






















 import { Page, Sort, Filter, Edit, Toolbar } from "@syncfusion/ej2-vue-grids";
import { Component, Vue } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import {MyGridOption} from '@/Helper/MyDataAdapter';
import { ClassType, Enums, UserType } from "@/Helper/Enum";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DataManager } from "@syncfusion/ej2-data";
import {createElement} from "@syncfusion/ej2-base";
Vue.use(GridPlugin);
let ClassTypeInstance:any = null;
let UserTypeInstance:any = null;
@Component({
     provide:{
     grid: [Page, Sort, Filter, Edit, Toolbar]
  }

})
export default class LiveClass extends Vue {
    ClassTypeFilter={ ui: {
create: function (args:any) {
                let flValInput = createElement('input', { className: 'flm-input' });
                args.target.appendChild(flValInput);
                ClassTypeInstance = new DropDownList({
                dataSource: new DataManager(Enums.ClassType),
                fields: { text: 'data', value: 'value' },
                placeholder: 'Select a value',
                popupHeight: '200px'
            });
            ClassTypeInstance.appendTo(flValInput);
            },
            write: function (args:any) {
                ClassTypeInstance.value = args.filteredValue;
            },
            read: function (args:any) {
                args.fltrObj.filterByColumn(args.column.field, args.operator, ClassTypeInstance.value);
            }
    }
    }
    UserTypeFilter={
 ui: {
create: function (args:any) {
                let flValInput = createElement('input', { className: 'flm-input' });
                args.target.appendChild(flValInput);
                UserTypeInstance = new DropDownList({
                dataSource: new DataManager(Enums.UserType),
                fields: { text: 'data', value: 'value' },
                placeholder: 'Select a value',
                popupHeight: '200px'
            });
            UserTypeInstance.appendTo(flValInput);
            },
            write: function (args:any) {
                UserTypeInstance.value = args.filteredValue;
            },
            read: function (args:any) {
                args.fltrObj.filterByColumn(args.column.field, args.operator, UserTypeInstance.value);
            }
 }
    }
     dataManager = MyGridOption.getDataManager('TeacherZoomFeedbackList');
    filterOptions = {    type: 'Menu'};
    wrapSettings= { wrapMode: 'Both ' };
    ClassType(field:any, data:any, column:any){
  return ClassType[data[field]] ;
}
UserType(field:any, data:any, column:any){
  return UserType[data[field]] ;
}
}
